@use "./variables" as *;

.form {
  .form_input_container {
    width: 80%;
    margin: 0 auto;
  }
}

.form_input {
  margin-bottom: 2rem;
  width: 100%;
  border: 1px solid #cfd4db;
  outline: none;
  padding: 8px 11px;
  border-radius: 5px;
}

.search_input {
  width: 80%;
}

.create_user_form {
  text-align: left;
  padding: 4rem 0;
  min-width: 95%;
  margin: 6rem auto 0 auto;
  color: $dark-grey;
  width: 400px;
  background-color: $white;
  border-radius: 0.4rem;

  label {
    display: block;
    font-size: 1.7rem;
    font-weight: 600;
    margin: 0.5rem 0;
  }

  button {
    display: block;
    margin: 3rem auto 0 auto;
  }
}

@media screen and (min-width: 768px) {
  .search_input {
    width: 50%;
  }

  .create_user_form {
    min-width: 70%;
  }
}
