@use "./_variables" as *;

.background_card {
  background-color: $white;
  margin: 3rem 0 5rem 0;
  border-radius: 0.4rem;
  padding: 4rem;
  box-shadow: $box-shadow;
}

.border_card_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3rem;
  height: 100%;
}

.border_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding: 3.5rem;
  border: solid 1px $dark-grey;
  border-radius: 0.4rem;

  h2 {
    text-transform: capitalize;
    margin: 0;
  }

  p {
    font-size: 1.8rem;
  }

  button {
    width: 100%;
  }
}

.tab_group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .tab {
    background-color: $white;
    padding: 1rem;
    margin: 1rem 0;
    min-width: 20rem;
    border-radius: 3rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-size: 2rem;
    box-shadow: $box-shadow;
  }
  .active {
    background-color: $primary;
    color: $white;
  }
}

.pill {
  background-color: $dark-grey;
  margin: 1rem auto;
  color: $white;
  padding: 0.5rem 2rem;
  text-align: center;
  border-radius: 1.7rem;
  width: 50%;
}

.danger_message {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  padding: 1rem 2rem;
  font-size: 1.8rem;
  font-weight: 600;
  border-radius: 0.4rem;
}

@media screen and (min-width: 992px) {
  .border_card_container {
    flex-direction: row;
    justify-content: center;
    gap: 4rem;
    padding: 3rem 1rem;
    height: 100%;
  }

  .border_card {
    width: 50%;
    padding: 3.5rem;
  }

  .tab_group {
    flex-direction: row;
    padding: 3rem;

    .tab {
      background-color: $white;
      margin: 0 3rem 0 0;
    }
    .active {
      background-color: $primary;
      color: $white;
    }
  }

  .pill {
    padding: 0.5rem 4rem;
    text-align: center;
    width: 100%;
  }
}
