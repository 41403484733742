@use "./variables" as *;

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal {
  position: fixed;
  top: 20vh;
  left: 5%;
  width: 90%;
  // height: 60%;
  background-color: $white;
  padding: 2rem;
  border-radius: 0.4rem;
  box-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.25);
  z-index: 30;
  text-align: center;
  // overflow: scroll;
  animation: slide-down 300ms ease-out forwards;

  .close {
    position: absolute;
    right: 2rem;
    font-weight: 800;
    font-size: 2rem;
    cursor: pointer;
  }
}

@media (min-width: 768px) {
  .modal {
    width: 60rem;
    max-width: 100%;
    left: calc(50% - 30rem);
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
