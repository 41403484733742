@use "./variables" as *;

.btn {
  box-shadow: 0 0.4rem 0.6rem rgb(50 50 93 / 11%),
    0 0.1rem 0.3rem rgb(0 0 0 / 8%);
  font-size: 1.7rem;
  font-weight: 500;
  line-height: 2.4rem;
  text-align: center;
  color: #fff;
  border-radius: 0.4rem;
  border: none;
  padding: 0.8rem 1.8rem;
  transition: all 0.2s ease-in;
  cursor: pointer;
}

.btn_primary {
  background-color: $primary;

  &:hover {
    background-color: darken($primary, 10%);
  }
}

.btn_edit {
  background-color: $edit;

  &:hover {
    background-color: darken($edit, 10%);
  }
}

.btn_delete {
  background-color: $red-color;

  &:hover {
    background-color: darken($red-color, 10%);
  }
}

.btn_success {
  background-color: $success;
  &:hover {
    background-color: darken($success, 10%);
  }
}

.btn_info {
  background-color: $info;
  cursor: inherit;
}

.btn_primary-accent {
  background-color: #e4e2e2;
  color: $dark-grey;
  box-shadow: none;
  &:hover {
    background-color: $red-color;
    color: $white;
    box-shadow: none;
  }
}
