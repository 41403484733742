.container {
  max-width: 1260px;
  margin: 0 auto;
  padding: 4rem 0;
  text-align: center;
}

.ml {
  display: inline-block;
  margin-left: 1rem;
}

.bg_grey {
  background-color: #f8f8f8;
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
}

.align_left {
  text-align: left;
}
