// ###### COLORS ######

$primary: #4f73cd;
$red-color: #ff033e;
$main-bg: #f1f1f1;
$dark-grey: #444;
$light-grey: #ccc;
$white: #fff;
$success: #66bb6a;
$edit: #ffb74d;
$info: #90a4ae;

$clip-path-sm: polygon(100% 0, 100% 75%, 50% 100%, 0 75%, 0 0);
$clip-path: polygon(100% 0, 100% 92%, 50% 100%, 0 92%, 0 0);

$box-shadow: 0 0.4rem 0.6rem rgb(50 50 93 / 11%),
  0 0.1rem 0.3rem rgb(0 0 0 / 8%);
