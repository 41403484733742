@use "./variables" as *;

.footer {
  background-color: $primary;
  color: #fff;
  font-size: 2rem;
  //   margin-top: 10rem;

  .container {
    padding: 3rem;

    p {
      margin: 0;
    }
  }
}
