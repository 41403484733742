@use "./partials/_variables" as *;
@use "./partials/_generics";
@use "./partials/_utilities";
@use "./partials/components";
@use "./partials/_buttons";
@use "./partials/modal";
@use "./partials/landing";
@use "./partials/forms";
@use "./partials/header";
@use "./partials/footer";

body {
  background-color: $main-bg;
  color: $dark-grey;
}

.error {
  color: $red-color;
}

.delete_confirm {
  padding: 4rem;
}

.error-message {
  font-size: 1.7rem;
}

.list_container {
  background-color: $white;
  height: 35rem;
  margin-bottom: 5rem;
  border-radius: 1.4rem;
  padding: 3rem;
  box-shadow: $box-shadow;
  h2 {
    color: #444;
  }

  &:has(h2) {
    height: 40rem;
    margin-top: 4rem;
  }
}

.fixture {
  display: flex;
  flex-direction: column;
  align-items: center;
}

ul:has(.fixture) {
  font-size: 2rem;
}

.fixture_info {
  font-size: 1.8rem;
  font-weight: 500;
}

.wide {
  width: 100%;
}

.form_group_holder {
  display: flex;
  gap: 3rem;
  width: 100%;
  .form_group {
    width: 50%;
  }
}

select {
  width: 100%;
}

.button_group {
  display: flex;
  margin: 0 auto;
  width: 20%;
  justify-content: center;
}

.profile_container {
  height: auto;
  flex-grow: 1;
}

.profile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15rem;
  text-align: justify;
  font-size: 1.7rem;

  li {
    padding: 0.6rem 0;
  }

  &_label {
    font-weight: 600;
  }

  p {
    display: inline-block;
  }
}

.controls_btn {
  align-self: flex-end;
}

.row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5rem;
  width: 100%;
}

.my_teams {
  flex-grow: 1;
}

.list {
  border: 1px solid $light-grey;
  border-radius: 0.4rem;
  font-size: 1.6rem;
  padding: 0.5rem 0;

  .list_item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    border-bottom: 1px solid $light-grey;
    padding: 1rem 2rem;
    &:last-child {
      border-bottom: none;
    }

    p {
      margin: 0;
    }
  }

  .list_group {
    border-bottom: 1px solid $light-grey;
    &:last-child {
      border-bottom: none;
    }
    li {
      border-bottom: none;
    }
  }
}

.border_bottom {
  border-bottom: 1px solid $light-grey;
  &:last-child {
    border-bottom: none;
  }
}

.p_3 {
  padding: 3rem;
}

.radio_container {
  display: flex;
  p {
    margin: 3rem 3rem 0 0;
    display: flex;
    label {
      margin-right: 1rem;
    }
  }
}

.kickOff_time {
  background-color: #e4e2e2;
  padding: 1rem 2rem;
  border-radius: 0.4rem;
  font-size: 2.2rem;
  font-weight: 600;
}

.homeTeam {
  h4 {
    margin-bottom: 0.7rem;
    font-size: 1.8rem;
  }
}

.home_venue,
.team_contact {
  padding: 1rem;
  border-bottom: 1px solid $light-grey;
}

.team_contact {
  margin-bottom: 1rem;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.center {
  display: grid;
  place-content: center;
}

@media screen and (min-width: 992px) {
  .row {
    flex-direction: row;
  }

  .list {
    font-size: 2rem;

    .list_item {
      flex-direction: row;
    }
  }

  .fixture_container {
    margin: 0 auto;
    width: 50%;
  }

  .fixture {
    flex-direction: row;
    align-items: baseline;
    gap: 6rem;
    padding: 1rem;

    &_time {
      width: 33%;
    }
  }

  .team_badge_holder,
  .team_fixture {
    width: 33%;
  }
}
