@use "./variables" as *;

.page_wrapper {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../../assets/football.jpg");
  background-position: center;
  background-size: cover;
  color: #fff;
}

.welcome_container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .welcome_text {
    height: 100%;
    h1 {
      font-size: 4rem;
      margin: 0;
      text-shadow: 0 1px 0 black;
      span {
        color: $red-color;
      }
    }
  }
}

.signin_container {
  max-width: 90%;
  color: $dark-grey;
  width: 400px;
  background-color: $main-bg;
  border-radius: 0.4rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  h3 {
    padding: 3rem;
    font-size: 3rem;
    margin: 0;
  }
  .signin_inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .signin_form {
      padding: 3rem;
      margin: 0 auto 3rem auto;
    }

    button {
      width: 100%;
      margin-top: 3rem;
    }

    .singnup_link {
      background-color: $red-color;
      color: #fff;
      padding: 4rem 0;
      cursor: pointer;
      .signup {
        font-size: 2rem;
        font-weight: 600;
      }
      p {
        font-weight: 600;
      }
    }
  }
}

@media screen and (min-width: 920px) {
  .page_wrapper {
    height: 100vh;
  }
  .welcome_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15rem;
    height: 100%;

    .welcome_text {
      height: 100%;
    }
  }
  .signin_container {
    margin-top: 5rem;
  }
}
